// import logo from './logo.svg';
// import './App.css';
import NavFile from './navigation/NavFile';
import Home from './pages/Home';

function App() {
  return (
    <div>
   
    <Home></Home>
    </div>
  );
}

export default App;
