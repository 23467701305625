import NavFile from '../navigation/NavFile'
import mainimage from '../images/main.png'
function Home() {
    return (
      <div>
    
        <NavFile></NavFile>
        <center>
        <img src={mainimage} style={{width:'100%',height:'auto'}}/>
        </center>
      </div>
    );
  }
  
  export default Home;