import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
// import NavDropdown from 'react-bootstrap/NavDropdown';
import codeworklogo from '../images/codework-logo.png'

function NavFile() {
  return (
    <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary">
      <Container>
        <Navbar.Brand href="#" style={{color:'#216acd',fontWeight:'bold',fontSize:'25px'}}>
        <img src={codeworklogo} width={50} height={50} alt="codework-logo" />CODEWORK
          </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            {/* <Nav.Link href="#features">Features</Nav.Link>
            <Nav.Link href="#pricing">Pricing</Nav.Link> */}
            {/* <NavDropdown title="Dropdown" id="collapsible-nav-dropdown">
              <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">
                Another action
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action/3.4">
                Separated link
              </NavDropdown.Item>
            </NavDropdown> */}
          </Nav>
          <Nav style={{color:'#216acd',fontSize:'20px'}}>
            <Nav.Link href="#" style={{color:'#216acd'}}>Home</Nav.Link>
            <Nav.Link href="#" style={{color:'#216acd'}}>Services</Nav.Link>
            <Nav.Link href="#" style={{color:'#216acd'}}>Courses</Nav.Link>
            <Nav.Link href="#" style={{color:'#216acd'}}>Contact</Nav.Link>
            <Nav.Link href="#" style={{color:'#216acd'}} >About</Nav.Link>
            
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavFile;